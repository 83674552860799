.contact-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
}

.team-member {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.team-member h3{
  font-size: 1.75rem;
  margin: 10px 0 0;
}

.team-description {
  margin: 40px auto;
  padding: 0 60px;
  font-size: 24px;
}

.team-member-description {
  margin-top: 20px;
  padding: 0 60px;
  font-size: 18px;
}

.about-us-heading {
  margin: 40px 0;
  text-align: center;
}

.about-us-text {
  text-align: center;
}

.contact-body {
  text-align: center;
}

.team-member img {
  height: 250px;
  width: 250px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid hsl(21, 80%, 74%);
}

@media (max-width: 768px) {
    .contact-grid {
        grid-template-columns: 1fr;
    }
}
