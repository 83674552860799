.container {
  width: auto;
  max-width: 1300px;
  padding-right: var(--bs-gutter-x,.75rem);
  padding-left: var(--bs-gutter-x,.75rem);
  margin-right: auto;
  margin-left: auto;
}

.homeObjOne-background {
  background-image: url('./home.jpg');
  /* Additional background properties */
}

.heading-container {
  display: flex;
  justify-content: center;
  margin: 70px 0;
}

.all-services-btn {
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}

.about {
  margin-bottom: 15rem;
}

.contact .row {
  width: 100%;
}
.row {
  display: flex;
  z-index: 1;
}
.col-md-6 {
  width: 49.9%;
}
.text-center {
  text-align: center;
}
.img-fluid {
  max-width: 60%;
  width: 50%;
}
.col-md-6 {
  width: 49.9%;
}
.main-title {
  font-size: 48px;
  color: #1c2237;
}
.main-p {
  font-size: 22px;
  font-weight: 500;
}
